import { Space } from "antd"
import { ReactElement } from "react"
import { TitleStyle } from "../About/Hero/Hero.styles"
import { categories } from "../About/RecentWork/RecentWorkConstants"
import Categories from "./Categories"
import { CroppedCircleStyle, DescriptionStyle, HeroTextStyle, ProjectsStyle } from "./Projects.styles"

const ProjectsContainer: React.FC = (): ReactElement => {
    return (
        <ProjectsStyle>
            <Space direction="vertical" size={150}>
                <TitleStyle direction="vertical" size={0}>
                    <CroppedCircleStyle>
                        <div className="circle" />
                    </CroppedCircleStyle>
                    <HeroTextStyle>
                        Look, I create <b>more</b>.
                    </HeroTextStyle>
                    <DescriptionStyle level={4}>For my designs, I use Adobe Photoshop, Adobe Illustrator, Canva and some other softwares.</DescriptionStyle>
                </TitleStyle>

                {categories.map((project, index) => (
                    <Categories key={`category-${index}`} category={project} />
                ))}
            </Space>
        </ProjectsStyle>
    )
}

export default ProjectsContainer
