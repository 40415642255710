import { Col, Typography } from "antd"
import styled from "styled-components"

//Constants
import { green_1 } from "../../../Constants/Colors"

export const RecentWorkTitleStyle = styled(Typography.Title)`
    font-size: 54px !important;
    font-weight: bold !important;
    color: ${green_1} !important;
`

export const CroppedCircleStyle = styled.div`
    background: none;
    width: 38vw;
    height: 50vw;
    overflow-x: hidden;
    right: 0;
    top: 90%;
    position: absolute;

    & .circle {
        width: 50vw;
        height: 50vw;
        border-radius: 50%;
        border: 1px solid ${green_1};
    }
`

export const SquareColImageStyle = styled(Col)`
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: calc(100% - 16px);

    & div.img-container {
        position: relative;
        display: inline-block;

        & img {
            aspect-ratio: 1 / 1.1;
            height: 100%;
            width: 100%;
            vertical-align: middle;
            object-fit: cover;
            object-position: top;
            border-style: none;
        }

        & div.img-mask {
            aspect-ratio: 1 / 1.1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            z-index: 3;

            &:hover {
                opacity: 1;
            }

            & div.img-mask-info {
                padding: 0 4px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
`

export const FullImagePreviewOverlayStyle = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: ${(props) => (props.theme.visible ? 1000 : -1)};
    opacity: ${(props) => (props.theme.visible ? 1 : 0)};

    transition: all 0.5s ease-in-out;
`

export const FullImagePreviewHeaderStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    padding: 10px 50px;
    z-index: 1000;
    display: flex;
    justify-content: end;
    align-items: center;

    & span {
        font-size: 36px;
        color: grey;
        padding: 5px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }
`

export const FullImagePreviewContainerStyle = styled.div`
    position: fixed;
    top: 100px;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh - 100px);
    padding: 25px 0;
`

export const FullImagePreviewImageContainerStyle = styled.div`
    width: 100%;
    padding: 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const ArrowIconStyle = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 36px;
    padding: 25px;
    cursor: ${(props) => (props.theme.visible ? "pointer" : "default")};
    transition: all 0.3s ease-in-out;
    color: grey;
    opacity: ${(props) => (props.theme.visible ? 1 : 0)};

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`

export const FullImagePreviewImageStyle = styled.img`
    padding: 0 25px;
    position: absolute;
    object-fit: scale-down;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: calc(100vh - 150px);
    opacity: ${(props) => (props.theme.visible ? 1 : 0)};
`

export const FullImagePreviewDescStyle = styled.div`
    width: 40vw;
    padding: 0 25px;
`
