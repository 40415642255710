import { Row, Typography } from "antd"
import styled from "styled-components"

//Constants
import { green_1 } from "../../Constants/Colors"

export const HeaderStyle = styled(Row)`
    margin: 0;
    height: 20vh;
`

export const HeaderHomeStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: lighter !important;

    &::after {
        content: "SURIA";
        font-weight: bold !important;
    }
`

export const HeaderMenuStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: bold !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: hsl(137, 70%, 40%) !important;
    }
`
