//Recent Work
import Garden8Main from "../../../Assets/Garden8/1.jpg"
import Podcast from "../../../Assets/Podcast/up-to-date.png"
import BerkatFarmMain from "../../../Assets/BerkatFarm/BF-A4-Poster.jpg"
import AmazonAstro from "../../../Assets/AmazonAstro/Amazon-Astro-Poster.jpg"
import NuroPrint from "../../../Assets/NuroPrint/nuro-print-ad.png"
import YunaBillboard from "../../../Assets/Yuna/yunaXF21-billboard.jpg"

//More Projects
import JointHands from "../../../Assets/MoreProjects/the-joint-hands.png"
import WeddingInvitation from "../../../Assets/MoreProjects/wedding-invitation.jpg"
import MagazineCover from "../../../Assets/MoreProjects/adore-magazine-cover.jpg"
import RootRemedies1 from "../../../Assets/MoreProjects/root-remedies-1.jpg"
import RootRemedies2 from "../../../Assets/MoreProjects/root-remedies-2.jpg"
import CrushIngredients from "../../../Assets/MoreProjects/crush-ingredients.jpg"
import RootRemediesRootsKit from "../../../Assets/MoreProjects/root-remedies-roots-kit.jpg"
import CrushDiscount from "../../../Assets/MoreProjects/crush-discount.jpg"
import CrushRitaRudaini from "../../../Assets/MoreProjects/crush-rita-rudaini.jpg"
import AdgencyRamadanFM from "../../../Assets/MoreProjects/adgency-ramadan-fm.jpg"
import Eidgency from "../../../Assets/MoreProjects/eidgency.png"
import AdgencyGrowWithUs from "../../../Assets/MoreProjects/adgency-grow-with-us.jpg"
import AdgencyAdvertising101 from "../../../Assets/MoreProjects/adgency-advertising-101.png"
import AdgencyFlood from "../../../Assets/MoreProjects/adgency-flood-1.jpg"
import AdgencyAdfiesta from "../../../Assets/MoreProjects/adgency-adfiesta.jpg"
import Billboard from "../../../Assets/MoreProjects/billboard.jpg"
import Magazine1 from "../../../Assets/MoreProjects/magazine-1.jpg"
import Magazine2 from "../../../Assets/MoreProjects/magazine-2.jpg"
import OhrringesCard from "../../../Assets/MoreProjects/ohrringes-card.jpg"
import SHRA from "../../../Assets/MoreProjects/shra.jpg"
import OhrringesPost1 from "../../../Assets/MoreProjects/ohrringes-ig-1.jpg"
import OhrringesPost2 from "../../../Assets/MoreProjects/ohrringes-ig-2.jpg"

export type TRecentWork = {
    id: number
    title: string
    desc: string
    links?: string[]
    src: string
}

export type TCategory = {
    id: number
    title: string
    projects: TRecentWork[]
}

export const recentWorks: TRecentWork[] = [
    {
        id: 1,
        title: "Garden 8 e-Book",
        desc: "I created an e-Book for Garden 8, the community garden in the neighborhood of Taman Perwira in Gombak Setia, KL.",
        links: ["https://cdn.me-qr.com/pdf/7742936.pdf"],
        src: Garden8Main,
    },
    {
        id: 2,
        title: "Podcast Cover",
        desc: "This is my first podcast, Up To Date! This podcast brings on latest topics and issue especially about lifestyle, trends, society, arts and entertainment.<br/><br/>Enjoy listening and stay up to date!",
        src: Podcast,
    },
    {
        id: 3,
        title: "Berkat Farm Qurban Poster",
        desc: "Berkat Farm's poster that promote livestock cows for Eid Al-Adha.<br/><br/>Feel free to visit their Instagram page!",
        links: ["https://www.instagram.com/berkatfarmtrading/"],
        src: BerkatFarmMain,
    },
    {
        id: 4,
        title: "Amazon Astro Billboard",
        desc: "This is a part of my university project for copywriting subject. This poster is for Astro by Amazon that targeted Malaysian audience.<br/><br/>Advertising Copywriting - 3rd semester of degree Advertising.",
        src: AmazonAstro,
    },
    {
        id: 5,
        title: "Nuro X Dominos for Qatar Fifa World Cup 2022 Poster",
        desc: "Another promotional poster I did for copywriting project. It is a collaboration campaign of Nuro and Dominos in promoting Qatar Fifa World Cup 2022.<br/><br/>Advertising Copywriting - 3rd semester of degree Advertising.",
        src: NuroPrint,
    },
    {
        id: 6,
        title: "Yuna X Forever 21 Billboard",
        desc: "This is a billboard mockup I created for Yuna X Forever 21 campaign.<br/><br/>Integrated Marketing Communication - 3rd semester of degree Advertising.",
        src: YunaBillboard,
    },
]

export const categories: TCategory[] = [
    {
        id: 1,
        title: "Posters",
        projects: [
            {
                id: 1,
                title: "Sardin Viral - Billboard",
                desc: "",
                src: Billboard,
            },
            {
                id: 2,
                title: "Sardin Viral - Magazine",
                desc: "",
                src: Magazine2,
            },
            {
                id: 3,
                title: "The Joint Hands",
                desc: "",
                src: JointHands,
            },
        ],
    },
    {
        id: 2,
        title: "Social Content",
        projects: [
            {
                id: 1,
                title: "Crush by Rita Rudaini",
                desc: "",
                src: CrushRitaRudaini,
            },
            {
                id: 2,
                title: "Crush: 40% OFF",
                desc: "",
                src: CrushDiscount,
            },
            {
                id: 3,
                title: "Crush: Makeup Cleanser Ingredients",
                desc: "",
                src: CrushIngredients,
            },
            {
                id: 4,
                title: "Root Remedies: Facewash",
                desc: "",
                src: RootRemedies1,
            },
            {
                id: 5,
                title: "Root Remedies: Baby Roots Kit",
                desc: "",
                src: RootRemedies2,
            },
            {
                id: 6,
                title: "Root Remedies: Baby Roots Kit",
                desc: "",
                src: RootRemediesRootsKit,
            },
            {
                id: 7,
                title: "Adgency Event: Ramadan FM",
                desc: "",
                src: AdgencyRamadanFM,
            },
            {
                id: 8,
                title: "Adgency Event: Eidgency",
                desc: "",
                src: Eidgency,
            },
            {
                id: 9,
                title: "Adgency Event: Grow With Us",
                desc: "",
                src: AdgencyGrowWithUs,
            },
            {
                id: 10,
                title: "Adgency Event: Advertising 101",
                desc: "",
                src: AdgencyAdvertising101,
            },
            {
                id: 11,
                title: "Adgency Event: Flood",
                desc: "",
                src: AdgencyFlood,
            },
            {
                id: 12,
                title: "Adgency Event: Adfiesta",
                desc: "",
                src: AdgencyAdfiesta,
            },
            {
                id: 13,
                title: "Ohrringes: IG Post 1",
                desc: "",
                src: OhrringesPost1,
            },
            {
                id: 14,
                title: "Ohrringes: IG Post 2",
                desc: "",
                src: OhrringesPost2,
            },
        ],
    },
    {
        id: 3,
        title: "Others",
        projects: [
            {
                id: 1,
                title: "Adore Magazine Cover",
                desc: "",
                src: MagazineCover,
            },
            {
                id: 2,
                title: "Selamat Hari Raya Aidilfitri Wish Card",
                desc: "",
                src: SHRA,
            },
            {
                id: 3,
                title: "Wedding Card",
                desc: "",
                src: WeddingInvitation,
            },
            {
                id: 4,
                title: "Ohrringes: Card",
                desc: "",
                src: OhrringesCard,
            },
        ],
    },
]

export const moreProjects: TRecentWork[] = []
