import styled from "styled-components"
import { Space, Typography } from "antd"

//Constants
import { green_1 } from "../../Constants/Colors"

export const FooterContainerStyle = styled(Space)`
    width: 100%;
    margin-top: 10vh;
    padding-top: 25px;
    border-top: 1px solid ${green_1};
`

export const FooterTextStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    font-weight: lighter !important;
`
