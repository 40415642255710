import React, { ReactElement } from "react"
import { Row, Col } from "antd"

//Components
import RuangSuria from "./RuangSuria"

//Styles
import { WelcomeTitleStyle } from "./Welcome.styles"

const WelcomeContainer: React.FC = (): ReactElement => {
    return (
        <React.Fragment>
            <Row justify="center">
                <Col>
                    <WelcomeTitleStyle>Welcome to Ruang Suria.</WelcomeTitleStyle>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <RuangSuria />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default WelcomeContainer
