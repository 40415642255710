import React from "react"
import ReactDOM from "react-dom"

//Components
import App from "./App"

//Styles
import "./index.css"
import "./index.less"
import "antd/dist/antd.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import AboutContainer from "./Containers/About/AboutContainer"
import ProjectsContainer from "./Containers/Projects/ProjectsContainer"
import ScrollToTop from "./Containers/ScrollToTop/ScrollToTop"

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="projects" element={<ProjectsContainer />} />
                    <Route path="" element={<AboutContainer />} />
                    <Route path="*" element={<AboutContainer />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
)
