import { Space, Tag, Typography } from "antd"
import styled, { keyframes } from "styled-components"

//Styles
import { green_1 } from "../../../Constants/Colors"

const spinning = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const TitleStyle = styled(Space)`
    margin-top: 10vh;
`

export const HeroTextStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 100px !important;
    font-weight: "bold";
    line-height: 90px !important;
    margin-bottom: 5px !important;
`

export const AdvertisingContainerStyle = styled(Space)`
    margin-bottom: 25px;

    &:hover {
        & #advertising-title {
            background: #fdff8a;
            transform: scale(1.075);
        }
        & #starburst-icon {
            width: 107.5px;
        }
    }
`

export const BorderedTextStyle = styled(Tag)`
    background: none;
    font-size: 92px;
    color: ${green_1};
    line-height: 75px;
    margin-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
    border-radius: 75px;
    border: 3px solid green;
    font-family: "Open Sans", sans-serif !important;
`

export const StarburstIconStyle = styled.div`
    width: 100px;
    position: relative;
    top: -30px;
    left: -25px;
    animation: ${spinning} infinite 20s linear;
    transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`

export const DescriptionStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    width: 70%;
    margin-bottom: 0 !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 300 !important;
`
