import { Tag, Typography } from "antd"
import styled from "styled-components"

//Constants
import { green_1 } from "../../../Constants/Colors"

export const WelcomeTitleStyle = styled(Typography.Title)`
    font-family: "Open Sans", sans-serif !important;
    font-size: 54px !important;
    font-weight: bold !important;
    color: ${green_1} !important;
`

export const BorderedTextStyle = styled(Tag)`
    background: none;
    font-size: 16px;
    color: ${green_1};
    line-height: 16px;
    padding: 10px 24px;
    border-radius: 50%;
    border: 2px solid green;
`

export const WelcomeDescriptionStyle = styled(Typography.Title)`
    font-family: "Open Sans", sans-serif !important;
    font-weight: lighter !important;
    color: ${green_1} !important;
    margin-bottom: 0 !important;
`
