import { Typography } from "antd"
import styled from "styled-components"
import { green_1 } from "../../Constants/Colors"

export const ProjectsStyle = styled.div`
    margin-top: 20vh;

    margin: 0;
`

export const HeroTextStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 72px !important;
    font-weight: lighter !important;
    line-height: 90px !important;
    margin-bottom: 5px !important;
`

export const DescriptionStyle = styled(Typography.Title)`
    color: ${green_1} !important;
    width: 70%;
    margin-bottom: 0 !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: lighter !important;
    line-height: 1.5rem !important;
`

export const CroppedCircleStyle = styled.div`
    background: none;
    width: 38vw;
    height: 50vw;
    overflow-x: hidden;
    left: 0;
    top: 25%;
    position: absolute;

    & .circle {
        width: 50vw;
        height: 50vw;
        border-radius: 50%;
        border: 1px solid ${green_1};
        transform: translateX(-30%);
    }
`
