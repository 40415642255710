import { Layout } from "antd"

//Components
import FooterContainer from "./Containers/Footer/FooterContainer"
import HeaderContainer from "./Containers/Header/HeaderContainer"

//Styles
import "./App.css"
import { BaseStyle, FooterStyle, LayoutStyle } from "./App.styles"
import { Outlet } from "react-router-dom"

function App() {
    return (
        <BaseStyle>
            <LayoutStyle>
                <HeaderContainer />
                <Layout.Content>
                    <Outlet />
                </Layout.Content>
                <FooterStyle>
                    <FooterContainer />
                </FooterStyle>
            </LayoutStyle>
        </BaseStyle>
    )
}

export default App
