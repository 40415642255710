import { Col, Row } from "antd"
import React, { ReactElement, useState } from "react"
import PreviewImage from "../About/RecentWork/PreviewImage"
import { RecentWorkTitleStyle, SquareColImageStyle } from "../About/RecentWork/RecentWork.styles"
import { TCategory, TRecentWork } from "../About/RecentWork/RecentWorkConstants"
import { RecentWorkContext } from "../About/RecentWork/RecentWorkContainer"
import { HeroTextStyle } from "./Projects.styles"

type TProps = {
    category: TCategory
}

const Categories: React.FC<TProps> = (props): ReactElement => {
    const { category } = props

    const [currImage, setCurrImage] = useState<TRecentWork | undefined>()

    return (
        <React.Fragment>
            <Row justify="center">
                <Col>
                    <RecentWorkTitleStyle>{category.title}.</RecentWorkTitleStyle>
                </Col>
            </Row>

            <RecentWorkContext.Provider
                value={{
                    currImage,
                    imageList: category.projects,

                    setCurrImage,
                }}
            >
                <Row gutter={[16, 16]}>
                    {category.projects.map((item: TRecentWork) => (
                        <SquareColImageStyle span={8}>
                            <PreviewImage item={item} />
                        </SquareColImageStyle>
                    ))}
                </Row>
            </RecentWorkContext.Provider>
        </React.Fragment>
    )
}

export default Categories
