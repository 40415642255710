import React, { ReactElement } from "react"

//Icons
import { ReactComponent as StarburstIcon } from "../../../Assets/Icons/starburst.svg"

//Styles
import { AdvertisingContainerStyle, BorderedTextStyle, DescriptionStyle, HeroTextStyle, StarburstIconStyle, TitleStyle } from "./Hero.styles"

const HeroContainer: React.FC = (): ReactElement => {
    return (
        <TitleStyle direction="vertical" size={0}>
            <HeroTextStyle>
                Hello, I am <i>Suria</i>, majoring in
            </HeroTextStyle>
            <AdvertisingContainerStyle>
                <BorderedTextStyle id="advertising-title">advertising.</BorderedTextStyle>
                <StarburstIconStyle id="starburst-icon">
                    <StarburstIcon />
                </StarburstIconStyle>
            </AdvertisingContainerStyle>
            <DescriptionStyle level={4}>I study Mass Communication at UiTM.</DescriptionStyle>
            <DescriptionStyle level={4}>I love designing and sometimes, make a profit out of it.</DescriptionStyle>
        </TitleStyle>
    )
}

export default HeroContainer
