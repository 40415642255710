import React, { ReactElement, useContext, useEffect, useState } from "react"
import { EyeOutlined } from "@ant-design/icons"

//Components
import FullImagePreview from "./FullImagePreview"

//Context
import { RecentWorkContext } from "./RecentWorkContainer"

//Types
import { TRecentWork } from "./RecentWorkConstants"

type TProps = {
    item: TRecentWork
}

const PreviewImage: React.FC<TProps> = (props): ReactElement => {
    const { item } = props

    const [visible, setVisible] = useState<boolean>(false)

    const { setCurrImage } = useContext(RecentWorkContext)

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [visible])

    const handleOnClickPreview = () => {
        setVisible((prev) => {
            if (prev) setCurrImage(undefined)
            else setCurrImage(item)

            return !prev
        })
    }

    return (
        <React.Fragment>
            <FullImagePreview visible={visible} handleVisibility={handleOnClickPreview} />
            <div className="img-container">
                <img src={item.src} alt="" />
                <div className="img-mask" onClick={handleOnClickPreview}>
                    <div className="img-mask-info">
                        <EyeOutlined /> Preview
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PreviewImage
