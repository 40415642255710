import React, { ReactElement } from "react"
import { Space } from "antd"

//Components
import MenuComponent from "./MenuComponent"

//Icons
import Logo from "../../Assets/Icons/ruangsuria_circle_feathered.png"

//Styles
import { HeaderHomeStyle, HeaderStyle } from "./Header.styles"
import { Link } from "react-router-dom"

export const HeaderHomeTitle = (): ReactElement => {
    return (
        <HeaderHomeStyle level={3} style={{ margin: 0 }}>
            RUANG
        </HeaderHomeStyle>
    )
}

const HeaderContainer: React.FC = (): ReactElement => {
    return (
        <HeaderStyle justify="space-between" align="middle">
            <Link to="/">
                <Space align="center">
                    <img src={Logo} style={{ width: 28, height: 28 }} alt="ruangsuria logo" />
                    <HeaderHomeTitle />
                </Space>
            </Link>

            <MenuComponent />
        </HeaderStyle>
    )
}

export default HeaderContainer
