import React, { ReactElement } from "react"
import { Col, Row, Space } from "antd"

//Components
import { HeaderHomeTitle } from "../Header/HeaderContainer"

//Icons
import { ReactComponent as TwitterIcon } from "../../Assets/Icons/twitter.svg"
import { ReactComponent as SpotifyIcon } from "../../Assets/Icons/spotify.svg"
import { ReactComponent as FacebookIcon } from "../../Assets/Icons/facebook.svg"
import { ReactComponent as LinkedInIcon } from "../../Assets/Icons/linkedin.svg"
import { ReactComponent as InstagramIcon } from "../../Assets/Icons/instagram.svg"

//Styles
import { FooterContainerStyle, FooterTextStyle } from "./Footer.styles"
import { Link } from "react-router-dom"

const FooterContainer: React.FC = (): ReactElement => {
    return (
        <FooterContainerStyle direction="vertical">
            <Row justify="center">
                <Col>
                    <FooterTextStyle level={4}>Connect with me on email or socials!</FooterTextStyle>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <Space size={25}>
                        <a href="https://www.facebook.com/suria.athira.54" target="_blank" rel="noreferrer">
                            <FacebookIcon />
                        </a>
                        <a href="https://twitter.com/_suriathira" target="_blank" rel="noreferrer">
                            <TwitterIcon />
                        </a>
                        <a href="https://www.instagram.com/suriaathira/" target="_blank" rel="noreferrer">
                            <InstagramIcon />
                        </a>
                        <a href="https://www.linkedin.com/in/suriaathirasuatau/" target="_blank" rel="noreferrer">
                            <LinkedInIcon />
                        </a>
                        <a href="https://open.spotify.com/user/httpsrthr?si=b0e35499e330492d" target="_blank" rel="noreferrer">
                            <SpotifyIcon />
                        </a>
                    </Space>
                </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 35 }}>
                <Col>
                    <Link to="/">
                        <HeaderHomeTitle />
                    </Link>
                </Col>
            </Row>
        </FooterContainerStyle>
    )
}

export default FooterContainer
