import React, { ReactElement, useContext } from "react"
import ReactDom from "react-dom"
import { Space, Typography } from "antd"
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons"

//Context
import { RecentWorkContext } from "./RecentWorkContainer"

//Styles
import {
    ArrowIconStyle,
    FullImagePreviewDescStyle,
    FullImagePreviewImageStyle,
    FullImagePreviewHeaderStyle,
    FullImagePreviewOverlayStyle,
    FullImagePreviewContainerStyle,
    FullImagePreviewImageContainerStyle,
} from "./RecentWork.styles"
import { VerticalBorderStyle } from "../../../App.styles"

type TProps = {
    visible: boolean

    handleVisibility: () => void
}

const FullImagePreview: React.FC<TProps> = (props): ReactElement => {
    const { visible, handleVisibility } = props

    const { currImage, imageList, setCurrImage } = useContext(RecentWorkContext)

    const restructureDescription = () => {
        let desc = currImage?.desc ?? ""

        if (currImage?.links) {
            desc += "<br/><br/>" + currImage.links.map((link) => `<a href="${link}" target="_blank">${link}</a>`).join("<br/>")
        }

        return desc
    }

    const handleOnPrev = () => {
        if (currImage && currImage.id > 1) {
            const prevImage = imageList.find((item) => item.id === currImage.id - 1)
            if (prevImage) {
                setCurrImage(prevImage)
            }
        }
    }

    const handleOnNext = () => {
        if (currImage && currImage.id < imageList.length) {
            const nextImage = imageList.find((item) => item.id === currImage.id + 1)
            if (nextImage) {
                setCurrImage(nextImage)
            }
        }
    }

    return ReactDom.createPortal(
        <FullImagePreviewOverlayStyle theme={{ visible }}>
            <FullImagePreviewHeaderStyle>
                <CloseOutlined onClick={handleVisibility} />
            </FullImagePreviewHeaderStyle>
            <FullImagePreviewContainerStyle>
                {currImage && (
                    <React.Fragment>
                        <ArrowIconStyle onClick={handleOnPrev} theme={{ visible: currImage.id > 1 }}>
                            <LeftOutlined />
                        </ArrowIconStyle>
                        <FullImagePreviewImageContainerStyle>
                            {imageList.map((el) => (
                                <FullImagePreviewImageStyle src={currImage.src} alt="" theme={{ visible: el.id === currImage.id }} />
                            ))}
                        </FullImagePreviewImageContainerStyle>
                        <ArrowIconStyle onClick={handleOnNext} theme={{ visible: currImage.id < imageList.length }}>
                            <RightOutlined />
                        </ArrowIconStyle>
                        <VerticalBorderStyle />
                        <FullImagePreviewDescStyle>
                            <Space direction="vertical">
                                <Typography.Title>{currImage.title}</Typography.Title>
                                <div dangerouslySetInnerHTML={{ __html: restructureDescription() }} />
                            </Space>
                        </FullImagePreviewDescStyle>
                    </React.Fragment>
                )}
            </FullImagePreviewContainerStyle>
        </FullImagePreviewOverlayStyle>,
        document.getElementById("recentWorkPortal")!
    )
}

export default FullImagePreview
