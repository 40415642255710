import React, { ReactElement, useState } from "react"
import { Col, Row, Space } from "antd"

//Components
import PreviewImage from "./PreviewImage"

//Constants
import { recentWorks } from "./RecentWorkConstants"

//Styles
import { ButtonStyle } from "../../../App.styles"
import { CroppedCircleStyle, RecentWorkTitleStyle, SquareColImageStyle } from "./RecentWork.styles"

//Types
import { TRecentWork } from "./RecentWorkConstants"
import { Link } from "react-router-dom"

type TRecentWorkContext = {
    currImage: TRecentWork | undefined
    imageList: TRecentWork[]

    setCurrImage: React.Dispatch<React.SetStateAction<TRecentWork | undefined>>
}

export const RecentWorkContext = React.createContext<TRecentWorkContext>({
    currImage: undefined,
    imageList: [],

    setCurrImage: () => {},
})

const RecentWorkContainer: React.FC = (): ReactElement => {
    const [currImage, setCurrImage] = useState<TRecentWork | undefined>()

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <CroppedCircleStyle>
                <div className="circle" />
            </CroppedCircleStyle>
            <Row justify="center">
                <Col>
                    <RecentWorkTitleStyle>Recent Works.</RecentWorkTitleStyle>
                </Col>
            </Row>

            <RecentWorkContext.Provider
                value={{
                    currImage,
                    imageList: recentWorks,

                    setCurrImage,
                }}
            >
                <Row gutter={[16, 16]}>
                    {recentWorks.map((item: TRecentWork) => (
                        <SquareColImageStyle span={8}>
                            <PreviewImage item={item} />
                        </SquareColImageStyle>
                    ))}
                </Row>
            </RecentWorkContext.Provider>

            <Row justify="center" style={{ marginTop: 30 }}>
                <Col>
                    <Link to="/projects">
                        <ButtonStyle size="large" shape="round">
                            See more
                        </ButtonStyle>
                    </Link>
                </Col>
            </Row>
        </Space>
    )
}

export default RecentWorkContainer
