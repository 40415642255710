import React, { ReactElement } from "react"
import { Space } from "antd"

//Components
import HeroContainer from "./Hero/HeroContainer"
import WelcomeContainer from "./Welcome/WelcomeContainer"
import RecentWorkContainer from "./RecentWork/RecentWorkContainer"

//Styles
import { AboutStyle } from "./About.styles"

const AboutContainer: React.FC = (): ReactElement => {
    return (
        <AboutStyle>
            <Space direction="vertical" size={150}>
                <HeroContainer />
                <WelcomeContainer />
                <RecentWorkContainer />
            </Space>
        </AboutStyle>
    )
}

export default AboutContainer
