import React, { ReactElement } from "react"
import { Col, Row, Space } from "antd"

//Styles
import { BorderedTextStyle, WelcomeDescriptionStyle } from "./Welcome.styles"

const RuangSuria: React.FC = (): ReactElement => {
    return (
        <Space>
            <BorderedTextStyle>
                Suria's
                <br />
                Space
            </BorderedTextStyle>
            <Space direction="vertical" size={0}>
                <Row justify="center">
                    <Col>
                        <WelcomeDescriptionStyle level={5}>Here, I share my creative works</WelcomeDescriptionStyle>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <WelcomeDescriptionStyle level={5}>and personal projects.</WelcomeDescriptionStyle>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <WelcomeDescriptionStyle level={5}>Yes, this is my portfolio.</WelcomeDescriptionStyle>
                    </Col>
                </Row>
            </Space>
        </Space>
    )
}

export default RuangSuria
