import React, { ReactElement } from "react"
import { Row, Col } from "antd"

//Styles
import { HeaderMenuStyle } from "./Header.styles"
import { Link } from "react-router-dom"

const MenuComponent: React.FC = (): ReactElement => {
    return (
        <Row justify="end" gutter={48}>
            <Col>
                <Link to="/">
                    <HeaderMenuStyle level={3}>About</HeaderMenuStyle>
                </Link>
            </Col>
            <Col>
                <Link to="/projects">
                    <HeaderMenuStyle level={3}>Projects</HeaderMenuStyle>
                </Link>
            </Col>
        </Row>
    )
}

export default MenuComponent
