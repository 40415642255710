import { Button, Layout } from "antd"
import styled from "styled-components"

//Constants
import { beige_1, green_1, yellow_1 } from "./Constants/Colors"

export const BaseStyle = styled.div`
    background: ${beige_1};
    min-height: 100vh;
`

export const LayoutStyle = styled(Layout)`
    background: none;
    margin: 0 15vw;
`

export const HeaderStyle = styled(Layout.Header)`
    background: none;
    height: 20vh;
`

export const ContentStyle = styled(Layout.Content)`
    background: none;
`

export const FooterStyle = styled(Layout.Footer)`
    background: none;
`

export const ButtonStyle = styled(Button)`
    background: none;
    border: 1px solid ${green_1};
    color: ${green_1};
    padding: 5px 25px !important;

    &:hover,
    &:focus {
        background: ${yellow_1};
        font-weight: bold;
        color: ${green_1};
        border: 1px solid ${green_1};
    }
`

export const VerticalBorderStyle = styled.div`
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
`
